<template>
  <div class="home">
    <div class="title">
      <div class="titleContent">
        <div class="titleContentLogo">
          <img src="../assets/logo.png" alt="" />
          <span>图片视频压缩</span>
        </div>
        <!-- 标题部分 -->
        <div class="titleCenter">
          <div
            class="centerItem"
            v-for="(item, index) in pulldownList"
            :key="index"
            @click="tabActive(item, index)"
          >
            <div class="huak" v-if="index === ishkactive"></div>
            <el-dropdown @command="btnDropdownItme">
              <span class="el-dropdown-link">
                {{ item.totalTitle }}<i class="el-icon-arrow-down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="zitem"
                  @click="btnDropdownItme(zitem)"
                  v-for="(zitem, jndex) in item.child"
                  :key="jndex"
                >
                  <div class="dropdownItem">
                    <img :src="zitem.zimg" alt="" />
                    <span>{{ zitem.zTitle }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="gy" @click="gywmBtn">
            关于我们
            <div class="huak" v-if="huakActive === 4"></div>
          </div>
        </div>
        

        <!-- 登录部分 -->
        <div class="login">
          <div class="member" @click="kaitVipBtn">
            <img class="teh" src="../assets/xians.png" alt="" />
            <img class="vip" src="../assets/vip.png" alt="" />
          </div>
          <div class="loginBtn" @click="btnlogin" v-if="!isuserInfo.isSign">
            登录
          </div>

          <div class="avatar" v-else @click="showUserInfoCard">
            <el-avatar :size="42" :src="isuserInfo?.avatar">
              <img src="@/assets/morentx.png" />
            </el-avatar>
            <span style="margin-left: 10px">{{ isuserInfo?.username }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- nr -->
    <div class="logicRegion" v-if="huakActive !== 4">
      <router-view></router-view>
    </div>
    <div v-else>
      <about></about>
    </div>
    <!-- 轮播 -->
    <div class="carousel" v-show="huakActive !== 4">
      <div class="bottomTitle">图片压缩/改尺寸仅三步</div>

      <div class="carouselContent">
        <div class="carouselContentLeft">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <img src="@/assets/swiper/oneb.png" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/swiper/twob.png" alt="" />
              </div>
              <div class="swiper-slide">
                <img src="@/assets/swiper/threeb.png" alt="" />
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>

        <div class="carouselContentRight">
          <div class="swiperRItem" @click="updateswiperCount(1)">
            <img
              v-if="swiperCount === 1"
              src="../assets/swiper/leftactiveone.png"
              alt=""
            />
            <img v-else src="../assets/swiper/leftactiveonew.png" alt="" />
          </div>

          <div class="swiperRItem" @click="updateswiperCount(2)">
            <img
              v-if="swiperCount === 2"
              src="../assets/swiper/leftactivetwo.png"
              alt=""
            />
            <img v-else src="../assets/swiper/leftactivetwow.png" alt="" />
          </div>

          <div class="swiperRItem" @click="updateswiperCount(3)">
            <img
              v-if="swiperCount === 3"
              src="../assets/swiper/leftactivethree.png"
              alt=""
            />
            <img v-else src="../assets/swiper/leftactivethreew.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="bottom">
      <bottom></bottom>
    </div>

    <!-- login -->
    <loginpop></loginpop>

    <!-- 开通vip弹出层 -->
    <popvip
      @closevippop="closevippop"
      @paysuccess="paysuccess"
      @payerror="payerror"
    ></popvip>

    <!-- 挽留弹窗 -->
    <detainment
      @paysuccess="paysuccess"
      @closevippopwl="closevippopwl"
      @payerror="payerror"
    ></detainment>

    <!-- 个人中心弹出层 -->
    <personage
      :grzxshow="grzxIsShow"
      @closegrzx="clisegrzx"
      @tapBtn="tapBtn"
    ></personage>

    <!-- 个人页 -->
    <div class="zzc" v-show="infocard" @click="gbuserinfo">
      <div class="gr" @click.stop>
        <div class="avatarName">
          <el-avatar :size="42" :src="isuserInfo.avatar">
            <img src="@/assets/morentx.png" />
          </el-avatar>
          <span style="margin-left: 10px">{{ isuserInfo.username }}</span>
        </div>

        <div class="card">
          <div class="novip" v-if="!isuserInfo.isVip">
            <div class="kaitVip" @click="updatispopvip(true)"></div>
          </div>
          <div class="yvip" v-else>
            <div class="yvipBtn" @click="updatispopvip(true)"></div>
            <div class="daoqi">到期时间：{{ computeVipTime }}</div>
          </div>
        </div>

        <div class="bottomTwoItem" @click="grzx">
          <img src="@/assets/user.png" alt="" />
          <span>个人中心</span>
        </div>

        <div class="bottomTwoItem" @click="logoutBtn">
          <img src="@/assets/logout.png" alt="" />
          <span>退出登录</span>
        </div>
      </div>
    </div>

    <!-- <div class="Scan" id="Scan">123</div> -->
  </div>
</template>

<script>
import assist from "@/utils/commonality";
import personage from "@/components/personage.vue";
import detainment from "@/components/detainment.vue";
import popvip from "@/components/kaitongvip.vue";
import bottom from "@/components/bottom.vue";
import loginpop from "../components/loginpop.vue";
import about from "@/components/gywm.vue";
import Swiper from "swiper";
import { TriggerDownload } from '@/api/upload'
import { mapActions, mapGetters, mapMutations } from "vuex";
// 模板已经适配, 1rem是37.5px  设计稿宽如果是375 ,好像是只是手机适配
export default {
  mixins: [],
  components: {
    loginpop,
    bottom,
    about,
    popvip,
    detainment,
    personage,
  },
  props: {},
  data() {
    return {
      // title标题
      pulldownList: [
        {
          id: 1,
          totalTitle: "图片视频压缩",
          sole: "图片压缩",
          pushUrl: "/",
          isdisabled: false,
          child: [
            {
              zid: 11,
              zTitle: "图片压缩",
              zimg: require("@/assets/compressicon/imgys.png"),
              pushUrl: "/",
            },
            // {
            //   zid: 12,
            //   zTitle: "GIF压缩",
            //   zimg: require("@/assets/compressicon/gifys.png"),
            //   pushUrl: "/gif",
            // }, 
            {
              zid: 13,
              zTitle: "证件照压缩",
              zimg: require("@/assets/compressicon/zjzys.png"),
              pushUrl: "/zjz",
            },
            {
              zid: 14,
              zTitle: "压缩指定大小",
              zimg: require("@/assets/compressicon/zhiding.png"),
              pushUrl: "/zd",
            },
            {
              zid: 15,
              zTitle: "图片改尺寸",
              zimg: require("@/assets/compressicon/imgchicun.png"),
              pushUrl: "/cc",
            },
            {
              zid: 16,
              zTitle: "修改DPI",
              zimg: require("@/assets/compressicon/dpi.png"),
              pushUrl: "/dpi",
            },
            {
              zid: 17,
              zTitle: "视频压缩",
              zimg: require("@/assets/compressicon/shipin.png"),
              pushUrl: "/sp",
            },
          ],
        },
        {
          id: 2,
          totalTitle: "图片格式转换",
          pushUrl: "/convert",
          sole: "图片格式转换",
          isdisabled: false,
          child: [
            {
              zid: 11,
              zTitle: "图片格式转换",
              zimg: require("@/assets/convert/imgzh.png"),
              pushUrl: "/convert",
            },
            {
              zid: 12,
              zTitle: "Word转图片",
              zimg: require("@/assets/convert/word.png"),
              pushUrl: "/convert/wordimg",
            },
            {
              zid: 13,
              zTitle: "PPT转图片",
              zimg: require("@/assets/convert/ppt.png"),
              pushUrl: "/convert/pptimg",
            },
            {
              zid: 14,
              zTitle: "Excel转图片",
              zimg: require("@/assets/convert/excel.png"),
              pushUrl: "/convert/excelimg",
            },
            // {
            //   zid: 15,
            //   zTitle: "HEIF转图片",
            //   zimg: require("@/assets/convert/heif.png"),
            //   pushUrl: "/convert/heifimg",
            // },
            // {
            //   zid: 16,
            //   zTitle: "TIFF转图片",
            //   zimg: require("@/assets/convert/tiff.png"),
            //   pushUrl: "/convert/tiffimg",
            // },
            {
              zid: 16,
              zTitle: "PDF转图片",
              zimg: require("@/assets/convert/pdf.png"),
              pushUrl: "/convert/pdfimg",
            },
            {
              zid: 16,
              zTitle: "图片转icon",
              zimg: require("@/assets/convert/icon.png"),
              pushUrl: "/convert/imgicon",
            },
          ],
        },
        {
          id: 3,
          totalTitle: "PDF转换",
          pushUrl: "/pdfcompress/pdfword",
          sole: "PDF转Word",
          isdisabled: false,
          child: [
            {
              zid: 11,
              zTitle: "PDF压缩",
              zimg: require("@/assets/pdf/pdfys.png"),
              pushUrl: "/pdfcompress",
            },
            {
              zid: 12,
              zTitle: "PDF转Word",
              zimg: require("@/assets/pdf/pdfword.png"),
              pushUrl: "/pdfcompress/pdfword",
            },
            {
              zid: 13,
              zTitle: "PDF转PPT",
              zimg: require("@/assets/pdf/pdfppt.png"),
              pushUrl: "/pdfcompress/pdfppt",
            },
            {
              zid: 14,
              zTitle: "PDF转Excel",
              zimg: require("@/assets/pdf/pdfexcel.png"),
              pushUrl: "/pdfcompress/pdfexcel",
            },
            {
              zid: 15,
              zTitle: "PDF转图片",
              zimg: require("@/assets/pdf/pdfimg.png"),
              pushUrl: "/pdfcompress/pdfimg",
            },
            {
              zid: 16,
              zTitle: "Word转PDF",
              zimg: require("@/assets/pdf/wordpdf.png"),
              pushUrl: "/pdfcompress/wordpdf",
            },
            {
              zid: 16,
              zTitle: "PPT转PDF",
              zimg: require("@/assets/pdf/pptpdf.png"),
              pushUrl: "/pdfcompress/pptpdf",
            },
            {
              zid: 16,
              zTitle: "Excel转PDF",
              zimg: require("@/assets/pdf/excelpdf.png"),
              pushUrl: "/pdfcompress/excelpdf",
            },
            {
              zid: 16,
              zTitle: "图片转PDF",
              zimg: require("@/assets/pdf/imgpdf.png"),
              pushUrl: "/pdfcompress/imgpdf",
            },
          ],
        },
        {
          id: 4,
          totalTitle: "PDF压缩",
          sole: "PDF压缩",
          pushUrl: "/pdfcompress",
          isdisabled: true,
          child: [
            {
              zid: 11,
              zTitle: "PDF压缩",
              zimg: require("@/assets/pdf/pdfys.png"),
              pushUrl: "/pdfcompress",
            },
            {
              zid: 12,
              zTitle: "PDF转Word",
              zimg: require("@/assets/pdf/pdfword.png"),
              pushUrl: "/pdfcompress/pdfword",
            },
            {
              zid: 13,
              zTitle: "PDF转PPT",
              zimg: require("@/assets/pdf/pdfppt.png"),
              pushUrl: "/pdfcompress/pdfppt",
            },
            {
              zid: 14,
              zTitle: "PDF转Excel",
              zimg: require("@/assets/pdf/pdfexcel.png"),
              pushUrl: "/pdfcompress/pdfexcel",
            },
            {
              zid: 15,
              zTitle: "PDF转图片",
              zimg: require("@/assets/pdf/pdfimg.png"),
              pushUrl: "/pdfcompress/pdfimg",
            },
            {
              zid: 16,
              zTitle: "Word转PDF",
              zimg: require("@/assets/pdf/wordpdf.png"),
              pushUrl: "/pdfcompress/wordpdf",
            },
            {
              zid: 16,
              zTitle: "PPT转PDF",
              zimg: require("@/assets/pdf/pptpdf.png"),
              pushUrl: "/pdfcompress/pptpdf",
            },
            {
              zid: 16,
              zTitle: "Excel转PDF",
              zimg: require("@/assets/pdf/excelpdf.png"),
              pushUrl: "/pdfcompress/excelpdf",
            },
            {
              zid: 16,
              zTitle: "图片转PDF",
              zimg: require("@/assets/pdf/imgpdf.png"),
              pushUrl: "/pdfcompress/imgpdf",
            },
          ],
        },
      ],
      huakActive: 0, //顶部滑块
      swiperCount: 1, //轮播图
      infocard: false, //控制个人信息的卡片
      ifvip: false, //是否是会员
      detainmentisShow: false, //控制挽留弹出层的
      grzxIsShow: false, //控制个人中心弹出层的
      payiserror: true, //是否是因为超时导致的挽留弹窗显示
    };
  },
  computed: {
    ...mapGetters([
      "issign",
      "isuserInfo",
      "isloginVisible",
      "isispopvip",
      "isdatedetainment",
      "ishkactive"
    ]),
    computeVipTime() {
      if (this.isuserInfo.vip_time === 4092595200) {
        return "永久会员";
      }
      // 获取并检查时间戳
      const vipTimestamp = Number(this.isuserInfo.vip_time); // 确保是数字类型

      // 转换时间戳为毫秒
      const date = new Date(vipTimestamp * 1000); // 乘以 1000 以转换为毫秒

      // 获取年份
      const year = date.getFullYear();

      // 获取月份，注意月份从 0 开始，所以需要加 1
      const month = String(date.getMonth() + 1).padStart(2, "0");

      // 获取日期
      const day = String(date.getDate()).padStart(2, "0");

      // 返回格式化后的日期
      return `${year}.${month}.${day}`;
    },
  },
  watch: {},
 created() {
      this.qg()
  },
  async mounted() { 
    // 判断等于bing的时候才执行
 
    (function (w, d, t, r, u) {//必应
      var f, n, i;
      (w[u] = w[u] || []),
        (f = function () {
          //根据渠道id做判断获取 ti
          // var o = { ti: "34313204", enableAutoSpaTracking: true };
          var o = { ti: assist.getRequest('e'), enableAutoSpaTracking: true };
          (o.q = w[u]), (w[u] = new UET(o)), w[u].push("pageLoad");
        }), 
        (n = d.createElement(t)),
        (n.src = r),
        (n.async = 1),
        (n.onload = n.onreadystatechange =
          function () {
            var s = this.readyState;
            (s && s !== "loaded" && s !== "complete") ||
              (f(), (n.onload = n.onreadystatechange = null));
          }),
        (i = d.getElementsByTagName(t)[0]),
        i.parentNode.insertBefore(n, i);
    })(window, document, "script", "//bat.bing.com/bat.js", "uetq");




    let islogin = this.$store.getters.isuid;
   
    if (islogin) {
      //这里是用户手机号登录的
      // 如果已经有uid 就判断用户是否登录
      if (!this.$store.state.userInfo.isSign) {
        //如果没有登录 就发起请求获取用户信息
        this.$store.dispatch("getuserinfo");
      }else {
        console.log('重新获取用户信息');
        this.$store.dispatch("getuserinfo");
      }
    } else {
      //这里是用户即没有扫码  也没有手机号登录 不做任何处理
      console.log("没有扫码也没有登录");
    }

    this.getDom();
  },
  methods: {
   randomString(len, chars) {
        len = len || 32;
        chars = chars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
        let maxPos = chars.length;
        let pwd = '';
        for (let i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
  async  qg() {

    },
    ...mapActions(["logout"]),
    ...mapMutations([
      "updateloginVisible",
      "updatispopvip",
      "updatedetainment",
    ]),
    btnDropdownItme(e) {
      this.$store.commit("updatetabactive", e.zTitle);
      // this.$router.push({
      //   path:`${e.pushUrl}?v=${e.zTitle}`
      // })
      this.$router.push(e.pushUrl);
    },
    gywmBtn() {
      //关于我们
      this.huakActive = 4;
    },
    tabActive(item, index) {
      this.$store.commit('updatehkactive',index)
      this.huakActive = index;
      this.$store.commit("updatetabactive", item.sole);
      this.$router.push(item.pushUrl);
    },
    getDom() {
      this.swiper = new Swiper(".mySwiper", {
        autoplay: true,
        direction: "vertical",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
      // 监听 slideChange 事件
      this.swiper.on("slideChange", () => {
        this.swiperCount = this.swiper.activeIndex + 1;
      });
    },
    updateswiperCount(index) {
      //轮播图一类的
      this.swiperCount = index;
      this.swiper.slideTo(index - 1);
    },
    btnlogin() {
      //打开login
      this.updateloginVisible(true);
    },
    showUserInfoCard() {
      //用户个人信息卡片打开
      this.infocard = true;
    },
    gbuserinfo() {
      //用户个人信息卡片关闭
      this.infocard = false;
    },
    grzx() {
      //点击个人信息的个人中心
      this.grzxIsShow = true;
      this.infocard = false;
    },
    async logoutBtn() {
      //退出登录
      await this.logout();
      this.infocard = false;
    },
    kaitVipBtn() {
      //开通vip的按钮
      if (this.isuserInfo.isSign) {
        //true就是登录了
        this.updatispopvip(true);
      } else {
        this.updateloginVisible(true);
      }
    },
    closevippop() {
      //关闭开通vip的按钮
      this.updatispopvip(false);
      // this.detainmentisShow = true //打开挽留弹窗
      if (this.payiserror) {
        this.updatedetainment(true);
      }
      setTimeout(() => {
        this.payiserror = true;
      }, 500);
    },
    closevippopwl() {
      //挽留的弹出层关闭
      // this.detainmentisShow= false
      this.updatedetainment(false);
    },
    clisegrzx() {
      //关闭个人中心的弹出层
      this.grzxIsShow = false;
    },
    tapBtn() {
      //点击了个人中心中的立即续费或者开通
      this.grzxIsShow = false;
      this.updatispopvip(true);
    },

    paysuccess() {
      //支付成功
      this.payiserror = false;
      this.updatispopvip(false);
      this.updatedetainment(false);
      this.$store.dispatch("getuserinfo"); //重新获取用户信息
      this.$tips("支付成功");
    },
    payerror() {
      //支付失败
      this.payiserror = false;
      this.updatispopvip(false);
      this.updatedetainment(false);
      this.$tips("支付超时，请刷新重试");
    },
  },
};
</script>



<style scoped lang="scss">
.home {
  background-color: #f2f4f6;
}
.dropdownItem {
  padding: 10px 0;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  .titleContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 100%;
  }
  .titleContentLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
    span {
      color: #333333;
      font-weight: 800;
      margin-left: 10px;
    }
  }
  .titleCenter {
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .el-dropdown {
      cursor: pointer;
      color: #333333 !important;
      font-weight: 600 !important;
      margin-right: 10px !important;
      i {
        margin-left: 5px !important;
      }
    }

    .centerItem {
      position: relative;
      .huak {
        position: absolute;
        width: 16px;
        height: 3px;
        bottom: -30%;
        left: 50%;
        transform: translateX(-12px);
        background-color: #ff7140;
        border-radius: 4px;
      }
    }
  }

  .gy {
    cursor: pointer;
    color: #333333 !important;
    font-weight: 600 !important;
    font-size: 16px;
    position: relative;
    .huak {
      position: absolute;
      width: 16px;
      height: 3px;
      bottom: -30%;
      left: 50%;
      transform: translateX(-12px);
      background-color: #ff7140;
    }
  }

  .login {
    display: flex;
    align-items: center;
    .member {
      cursor: pointer;
      position: relative;
      width: 96px;
      height: 36px;
      background: url("@/assets/kait.png") no-repeat;
      background-size: 100% 100%;
      .teh {
        position: absolute;
        top: -25%;
        right: -25%;
        width: 80px;
        height: 19px;
      }
      .vip {
        position: absolute;
        top: 22%;
        left: 10%;
        width: 20px;
        height: 18px;
      }
    }
    .loginBtn {
      width: 96px;
      height: 36px;
      margin-left: 27px;
      background-color: #ff7540;
      text-align: center;
      line-height: 36px;
      border-radius: 10px;
      color: #ffffff;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.logicRegion {
  margin: 24px auto 24px;
  width: 80%;
}

// 轮播
.carousel {
  width: 100%;
  background-color: #ffffff;
  height: 750px;
  padding-top: 0px;

  .bottomTitle {
    text-align: center;
    margin: 0 auto;
    padding: 60px 0;
    font-size: 35px;
    color: #000;
  }

  .carouselContent {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .carouselContentLeft {
      width: 70%;
      height: 550px;
      overflow: hidden;
    }
    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: auto;
      height: 100%;
    }

    .carouselContentRight {
      width: 26%;
      height: 550px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .swiperRItem {
        width: 346px;
        height: 117px;
        cursor: pointer;
      }
    }
  }
}

// 底部
.bottom {
  background: #000000;
  width: 100%;
  padding-bottom: 30px;
}

.avatar {
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.zzc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 100;
}
.gr {
  position: fixed;
  right: 5%;
  top: 10%;
  width: 229px;
  height: 246px;
  background-color: #fff;
  border-radius: 18px;
  z-index: 999999;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  display: flex;
  flex-direction: column;

  .card {
    .novip,
    .yvip {
      position: relative;
      width: 189px;
      height: 60px;
      margin-top: 15px;
    }
    .novip {
      background: url("@/assets/novip.png") no-repeat;
      background-size: 100% 100%;
      .kaitVip {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        width: 62px;
        height: 26px;
        background: url("@/assets/kaitong.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .yvip {
      background: url("@/assets/yvip.png") no-repeat;
      background-size: 100% 100%;
      .yvipBtn {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        width: 62px;
        height: 26px;
        background: url("@/assets/xufei.png") no-repeat;
        background-size: 100% 100%;
      }
      .daoqi {
        position: absolute;
        bottom: 20%;
        left: 6%;
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
  .avatarName {
    display: flex;
    align-items: center;
  }
  .bottomTwoItem {
    cursor: pointer;
    margin-top: 10px;
    img {
      width: 32px;
      height: 32px;
    }
    display: flex;
    align-items: center;
  }
}
</style>
