
import CryptoJS from 'crypto-js'
import { getUserUid} from '@/api/login'
import { getspQuest,bingApi } from '@/api/upload'
const   assist= {
  search: function (arr, key, value, isMultiple = false) {
    let data = [];
    for (let i in arr) {
        if (typeof arr[i][key] != 'undefined' && arr[i][key] == value) {
            if (isMultiple) {
                data.push(arr[i]);
            } else {
                return arr[i];
            }
        }
    }
    return data;
},

like: function (arr, key, like) { // 模糊搜索
  let data = [];
  for (let i in arr) {
      if (typeof arr[i][key] != 'undefined' && ('' + arr[i][key]).indexOf(like) != -1) {
          data.push(arr[i]);
      }
  }
  return data;
},
date: function (format, timestamp) {
    format = format || 'Y-m-d';
    timestamp = timestamp || Math.ceil((new Date().getTime()) / 1000);
    let date = new Date(timestamp * 1000);
    return format.replace(/Y/g, date.getFullYear())
        .replace(/m/g, assist.makeZero(date.getMonth() + 1))
        .replace(/d/g, assist.makeZero(date.getDate()))
        .replace(/H/g, assist.makeZero(date.getHours()))
        .replace(/i/g, assist.makeZero(date.getMinutes()))
        .replace(/s/g, assist.makeZero(date.getSeconds()))
},
makeZero: function (num) {
    if (num <= 9) {
        return '0' + parseInt(num);
    }
    return num;
},

count: function (o) {
  let t = typeof o;
  if (t === 'string') {
      return o.length;
  } else if (t === 'object') {
      let n = 0;
      for (let i in o) {
          n++;
      }
      return n;
  }
  return false;
},
makeZero: function (num) {
  if (num <= 9) {
      return '0' + parseInt(num);
  }
  return num;
},


key : 'ma375a413b54ee19c3ba180911882fe0',

decrypt: function (e) {//解密
    e = decodeURIComponent(e);
    let key = CryptoJS.enc.Utf8.parse(this.key)
    let decryptedData = CryptoJS.AES.decrypt(e, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    let m = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(m);
},
decrypt2: function (e) {//解密
    e = decodeURIComponent(e);
    let key = CryptoJS.enc.Utf8.parse(this.key)
    let decryptedData = CryptoJS.AES.decrypt(e, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    let m = decryptedData.toString(CryptoJS.enc.Utf8);
    return this.decrypt(m);
},
encrypt: function (e) {//加密
    e = JSON.stringify(e);
    let key = CryptoJS.enc.Utf8.parse(this.key)
    let encryptedData = CryptoJS.AES.encrypt(e, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData.toString();
},


formatFileSize: function(size) { //转换大小的 显示的kb 或者mb
    // 定义转换单位
    const KB = 1024;
    const MB = 1024 * KB;
  
    // 检查文件大小
    if (size >= MB) {
      return (size / MB).toFixed(2) + ' MB';
    } else if (size >= KB) {
      return (size / KB).toFixed(2) + ' KB';
    } else {
      return size + ' KB';
    }
  },



empty: function (val) {
    return val === '' || val === null || val === undefined || val === '0' || val === 0;
},
//headers 的值
    app_id: 1301,
    packet: 'bdds',
    channel_id: 14772,
    platform_id: 4,
    version: '1.0',
    os: '1',
    
    //   getUid :function() {
    //       return this.getRequest('uid');
    //   },
    getUid: async function (bool) {
        let uid;
        uid = this.getCache('uid');
        if (!(this.empty(uid))) {
            return uid;
        }
        uid = this.getRequest('uid');
        if (!(this.empty(uid))) {
            return uid;
        }
        if (!bool) {
            let user = await getUserUid();
            uid = user.data.uid || 0;
            if (this.empty(uid)) {
                return 0;
            }
        }
        this.setCache('uid',uid);
        return uid;
    },
      getVersion: function () {
        return this.getRequest('version') || this.version;
    },
    getAppId: function () {//项目id
      let appId = assist.getRequest('app_id');
  
      return appId || this.app_id;
    },
    getChannelId: function () {//渠道id
      let c;
      c = this.getRequest('channel_id');
      if (c) {
          return c;
      }
      return this.channel_id;
  },
    getPacket: function () { //写死的对应规则
      let packet = this.getRequest('packet');
      if (packet) {
          return packet;
      }
      switch (this.getPlatform()) {
          case '1':
              return 'juliang';
          case '2': // 快手
              return 'kwaiAds';
          case "4":
              return 'bdds';
          case "6":
              return 'bdxxl';
          case '9':
              return 'bxm';
          case '10':
              return 'tuia';
          case '11':
              return 'jizun';
          case '12':
              return 'baicuan';
          case '13':
              return 'xiangdian';
          case '14':
              return 'xmob';
          case '15':
              return 'wancuan';
          case '18':
              return 'cjhcuc';
          case '24':
              return 'clue360';
          case '34':
              return 'bing';
          default:
              return this.packet;
      }
  },
  getPlatform: function () {
    return this.getRequest('platform_id') || this.platform_id;
},
getOtherId: function () {
  switch (this.getPacket()) {
      case 'bdds':
          return this.getRequest('bd_vid') || ''; // 百度大搜
      case 'bdxxl':
          return this.getRequest('bd_vid') || ''; // 百度信息流
      case 'bxm':
          return this.getRequest('bxm_id') || ''; // 变现猫
      case 'tuia':
          return this.getRequest('a_oId') || ''; // 推啊
      case 'jizun':
          return this.getRequest('jcid') || ''; //极准
      case 'xmob':
          return this.getRequest('mob_cid') || ''; //XMOB
      case 'xiangdian':
          return this.getRequest('landingid') || ''; //享点
      case 'wancuan':
          return this.getRequest('qcjParamStr') || ''; //万川
      case 'baicuan':
          return this.getRequest('bc_tag') || ''; //百川
      case 'kwaiAds':
          return this.getRequest('callback') || ''; //快手
      case 'juliang':
          return this.getRequest('clickid') || ''; // 巨量
      case 'cjhcuc':
          return this.getRequest('uctrackid') || ''; // uc浏览器
      case 'clue360':
          return this.getRequest('qhclickid') || ''; // 360线索
      case 'bing':
          return this.getRequest('msclkid') || ''; // bing
      default:
          return '';
  }
},
getOs: function () {//操作系统 写死1
    return this.os || 1;
},

  getRequest: function (key) {
    let get = this.getParam();
    if (typeof key === 'undefined') {
        return '';
    }
    return get[key] || undefined;
},


  //解析rul的各个值作为对象
 //实例
 //let params = getParam('https://example.com/page?name=John&age=30&city=New%20York');

 //输出 {
//   name: "John",
//   age: "30",
//   city: "New York"
// }

// getParam  :function (url) {  
//   url = url || window.document.location.href.toString();
//   url = url.split("#")[0];
//   let u = url.split("?");
//   let get = [];
//   if (typeof u[1] === 'string') {
//       u = u[1].split('&');
//       for (let i in u) {
//           let j = u[i].split('=');
//           get[j[0]] = decodeURIComponent(j[1]);
//       }
//   }
//   return get;
// },
getParam :function (url) {  
    url = url || window.document.location.href.toString();

    // 用来存储参数的对象
    let params = {};

    // 处理 `?` 后的参数
    let queryString = url.split("#")[0].split("?")[1];
    if (queryString) {
        queryString.split("&").forEach(param => {
            let [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });
    }

    // 处理 `#` 后的参数
    let hashString = url.split("#")[1];
    if (hashString && hashString.includes("?")) {
        hashString.split("?")[1].split("&").forEach(param => {
            let [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });
    }

    return params;
},
// getParam: function (url) {
//     // 使用默认 URL 或者当前页面的 URL
//     url = url || window.document.location.href.toString();
    
//     // 分离 URL 和哈希部分
//     let hashIndex = url.indexOf("#");
//     let queryString = hashIndex !== -1 ? url.slice(hashIndex + 1) : url;
    
//     // 分离查询参数
//     let u = queryString.split("?");
//     let params = {};
  
//     if (u[1]) {
//       // 分离键值对
//       u = u[1].split('&');
//       for (let i = 0; i < u.length; i++) {
//         let param = u[i].split('=');
//         // 解码键和值并存入对象
//         let key = decodeURIComponent(param[0]);
//         let value = decodeURIComponent(param[1] || '');
//         params[key] = value;
//       }
//     }
//     return params;
//   },

jwt: {
    key: 'token_jwt',
    get: function () {
        return assist.getCache(this.key);
    },
    set: function (value) {
        return assist.setCache(this.key, value);
    },
    clear: function () {
        return assist.clearCache(this.key);
    }
},

prefix: 'compress_',
setCache: function (key, value, expire) {
    key = this.prefix + key;
    expire = expire || (new Date()).getTime() + 86400000;
    localStorage.setItem(key, JSON.stringify({
        data: value,
        expire: expire
    }));
},
getCache: function (key, def) {
    key = this.prefix + key;
    let curTime = (new Date()).getTime();
    let data = localStorage.getItem(key);
    if (!data) {
        return def;
    }
    let dataObj = JSON.parse(data);
    if (curTime > dataObj.expire) {
        localStorage.removeItem(key);
        return def;
    } else {
        return dataObj.data;
    }
},
clearCache: function (key) {
    key = this.prefix + key;
    let data = localStorage.getItem(key);
    if (!data) {
        return;
    }
    localStorage.removeItem(key);
},

setUrl: function (url, data, removeData) {//微信扫码登录 redirect_uri 调用的
    data = data || [];
    removeData = removeData || [];
    let ar = [];
    let param = this.getParam();
    let param2 = this.getParam(url);
    for (let j in param2) {
        param[j] = param2[j];
    }
    for (let j in data) {
        param[j] = data[j];
    }
    for (let i in param) {
        if (removeData.indexOf(i) === -1) {
            ar.push(i + '=' + param[i]);
        }
    }
    let newUrl = url.split('?');
    if (newUrl[0].substring(0, 1) === '/') {
        newUrl[0] = this.getRoot(newUrl[0]);
    }
    if (this.count(ar) >= 1) {
        return newUrl[0] + '?' + ar.join('&');
    }
    return newUrl[0];
},

randomString: function (len, chars) {
    len = len || 32;
    chars = chars || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    let maxPos = chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
},
 normalizeUrl:function(url) {
    // 提取 `#` 之前的部分
    let baseUrl = url.split('#')[0];

    // 提取 `#` 之后的部分
    let hashPart = url.split('#')[1] || "";

    // 检查 `#` 后面是否包含参数
    let queryParams = "";
    if (hashPart.includes('?')) {
        queryParams = hashPart.split('?')[1];
    }

    // 构造新的 URL，移除路径部分
    let normalizedUrl = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;

    return normalizedUrl;
},



// bing 的api
    // api: async function (price, out_trade_no, callback) { //价格 订单号 回调方法  需要支付价格跟订单号
    // let res = '';
    // if (this.getPacket()=='bing') {
    //     let res = await bingApi({
    //         out_trade_no:out_trade_no
    //     })
    //     //  let res = await request.sendCenterApi({
    //     //     path: assist.centerAPI + '/qy/bing_back',
    //     //     method: 'GET',
    //     //     data: {
    //     //         out_trade_no: out_trade_no //付款的订单
    //     //     }
    //     // });

    //     console.log('这里是api中的bing');
    //     console.log(res);
    //     if (res.data.status === 1) {
    //           window.uetq = window.uetq || [];
    //     window.uetq.push('event', 'purchase', {"revenue_value": price, "currency": "CNY"});
    //     }
    // }
       
    //     // 成功以后自定义逻辑
    //     //需要判断  
    //     /**
    //      * 判断当前的环境是不是必应  判断回传成功没
    //      */
    //     callback && callback();
    //     return res;
    // }

downloadFile: function (url,name,type) {
   let newname =  url.split('?')[0]
  let fileName =name +  newname.match(/\.([^\.\/\?]+)$/)[0]
   
   let res =  assist.setUrl('https://proxydown.sanwubeixin.cn/download',{
     url:encodeURIComponent(url) ,
     default_name:fileName
   })
   window.open(res)
    }


  // let res =  assist.setUrl('https://proxydown.sanwubeixin.cn/download',{
  //    url:'https://oss-shanghai.sanwubeixin.cn/yearCache/DomConvert/20240705/85134041/172015097077506032e7b6ae-bf11-4269-8622-3ee8dd3f7c73.pptx',
  //    default_name:'文件.doc'
  //  })
  //  console.log(this.randomString());
  //   console.log(res);
  //   window.open(res)

}

export default assist